<template>
  <v-container class="min-height-100">
    <v-row class="min-height-100 auth-page login-page ma-0">
      <!-- Left section that's hidden on phones -->
      <v-col class="d-none d-md-flex" md="6">
        <div
          class="logo-container d-flex align-center px-12 cursor-pointer"
          :class="host.theme.type === 'dark' ? 'black' : 'white'"
          @click="$router.push({ name: 'Home' })"
        >
          <img :src="host.images.logo" />
        </div>
      </v-col>

      <!-- Right section that does have a form -->
      <v-col
        class="min-height-100"
        cols="12"
        md="6"
      >
        <div
          v-if="host.allowRegistration"
          class="text-right text-subtitle-2"
        >
          Don't have an account? &nbsp;
          <router-link class="text-decoration-none" :to="{ name: 'Register' }">
            Get Started
          </router-link>
        </div>

        <div class="form-container d-flex flex-column justify-center min-height-100">
          <div class="text-h5 font-weight-bold">
            Sign in to {{ host.name }}
          </div>
          <div class="text-subtitle-1 mt-3 mb-8 grey--text text--darken-2">
            Enter your details below.
          </div>

          <v-form @submit.prevent="handleSubmit">
            <v-text-field
              v-model.trim="form.email"
              type="email"
              :error-messages="emailErrors"
              label="Email"
              outlined
              required
              @input="$v.form.email.$touch()"
              @blur="$v.form.email.$touch()"
            ></v-text-field>

            <v-text-field
              v-model.trim="form.password"
              type="password"
              :error-messages="passwordErrors"
              label="Password"
              outlined
              required
              @input="$v.form.password.$touch()"
              @blur="$v.form.password.$touch()"
            ></v-text-field>

            <div class="d-flex justify-space-between align-center">
              <router-link
                :to="{ name: 'ForgotPassword' }"
                class="text-decoration-none text-body-2 font-weight-bold"
              >
                Forgot Password?
              </router-link>

              <v-btn
                :loading="isLoading"
                :disabled="isLoading"
                :class="{ 'shadow--primary': !isLoading }"
                color="buttonPrimary"
                type="submit"
                depressed
                large
              >
                Login
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { required, minLength, email } from "vuelidate/lib/validators"
import { localStorageManager, API_DATA } from "@/helpers/localStorageManager.js"

const originalForm = () => ({
  email: "",
  password: ""
})

export default {
  name: "Login",

  data: () => ({
    isLoading: false,
    form: originalForm()
  }),

  computed: {
    emailErrors () {
      // initiate the array of error messages
      const errors = []

      // if there has been no activity, show no error
      if (!this.$v.form.email.$dirty) return errors

      // else test it
      !this.$v.form.email.email && errors.push('Must be valid e-mail')
      !this.$v.form.email.required && errors.push('E-mail is required')

      // return the error messages
      return errors
    },

    passwordErrors () {
      // initiate the array of error messages
      const errors = []

      // if there has been no activity, show no error
      if (!this.$v.form.password.$dirty) return errors

      // else test it
      !this.$v.form.password.required && errors.push('Password is required')
      !this.$v.form.password.minLength && errors.push('Password must be 8 characters long')

      // return the error messages
      return errors
    },
  },

  // Define the vuelidate validation rules
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(8)
      }
    }
  },

  methods: {
    async handleSubmit() {
      // disable the button
      this.isLoading = true

      // Run validations
      await this.$v.$touch()

      // If any validation error, stop the execution
      if (this.$v.$anyError) {
        this.isLoading = false

        return false
      }

      // Show a loader while the request is being processed
      const loaderId = Symbol()
      await this.$store.dispatch("loaders/add", loaderId)

      try {
        // Make the network request
        const response = await axios({
          url: "/api/login",
          method: "POST",
          data: {
            email: this.form.email.trim(),
            password: this.form.password.trim()
          }
        })

        // Store the access token from API in storage
        localStorageManager({
          name: API_DATA,
          key: "access_token",
          value: response.data.token
        })

        // Fetch details from API
        await this.$store.dispatch("auth/fetchProfile")

        // Update the global profileData object for vue-router
        window.profileData = this.$store.getters["auth/profile"]

        // Get the redirect path if it exists
        const redirectPath = window.sessionStorage.getItem("redirectAfterLogin") || null

        // Also delete the data from sessionStorage
        window.sessionStorage.removeItem("redirectAfterLogin")

        // Redirect the user to this path
        window.location.href = typeof redirectPath === "string" ? redirectPath : "/dashboard"
      } catch (error) {
        logger({ type: "Authentication Error", error })

        // Show the error message to the user
        this.$store.dispatch("toasts/add", { text: error?.response?.data?.message || "Invalid email or password" })
      } finally {
        // remove the loader
        await this.$store.dispatch("loaders/remove", loaderId)

        // enable the button
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="stylus" src="@/stylus/auth.styl"></style>
